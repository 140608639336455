.viewMessage {
    min-width: 600px;
    max-width: 800px;
    border-radius: 10px;
    border: 1px solid var(--menu-button);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;
        border-bottom: 1px solid var(--menu-button);
    }

    &__title {
        max-width: 60%;
    }

    &__date {
        max-width: 30%;
    }

    &__body {
        background-color: var(--secondary-bg);
        padding: 20px;
        height: 250px;
        overflow: auto;
    }

    &__footer {
        border-top: 1px solid var(--menu-button);
        padding: 10px;
        text-align: center;
    }
}