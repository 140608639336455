.confirmDelete {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;

  &-title {
    text-align: center;
    font-size: 19px;
    font-weight: 500;
  }

  &-subTitle {
    text-align: center;
    line-height: 1.2;
  }

  &-actions {
    margin: 30px auto 0;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
}