.inkassators {
    &-filter {
        gap: 20px;
        min-width: 330px;
        width: 100%;
        display: grid;
        grid-template-columns: 0.5fr 1.7fr 1.3fr 0.75fr 0.75fr;
    }

    &__table {
        margin-top: 30px;
    }

    .MuiAutocomplete-root  {
        width: 100%;
    }

    .MuiOutlinedInput-root {
        width: 100%;

        &:hover {
            border-color: #495057;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: #495057;

        &:hover {
            border-color: #495057 !important;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: #495057 !important;

        &:hover {
            border-color: #495057 !important;
        }
    }

    .Mui-focused {
        border-color: #495057 !important;
    }

    .MuiOutlinedInput-root {
        color: var(--primary-text);
        &:hover {
          border-color: #495057;
        }
      }
}

.inkassator {
    &__title {
        text-align: center;
        font-size: 22px;
    }

    &__table {
        margin-top: 30px;
    }

    &__nothing {
        font-size: 24px;
        margin-top: 50px;
        text-align: center;
    }

    hr {
        border: 2px solid red;
        border-radius: 50%;
        margin: 20px 0;
    }
}