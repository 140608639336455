.create-request {
  margin-bottom: 1rem;
}
.create-request .MuiStepLabel-label.Mui-active {
  color: var(--primary-text);
}

.create-request .MuiStepLabel-label.Mui-completed {
  color: #1976d2;
}

.create-request .MuiStepLabel-label.Mui-disabled {
  color: var(--disabled-text);
  opacity: 0.5;
}

.inner-dep-info {
  margin: 1.5rem 0;
}

.department-filters-item-input.department-select {
  max-width: calc(100% - 400px);
}

.cash-transit-input .MuiOutlinedInput-root, 
.cash-transit-input .MuiOutlinedInput-root .Mui-disabled {
  -webkit-text-fill-color: var(--primary-text);
}
.cash-transit-input .Mui-disabled .MuiOutlinedInput-notchedOutline {
  border: 0px;
  border-color: #495057 !important;
  transition: 3s;
}
.cash-transit-input .MuiOutlinedInput-notchedOutline {
  border: 1px solid;
  border-color: #495057 !important;
  transition: 4s;
}

.cash-transit-input .Mui-disabled .MuiSvgIcon-root  {
  opacity: 0;
}

.collapse-table {
  max-width: 1100px; 
  background-color: var(--secondary-bg); 
  border-radius: 15px;

  & tr:last-child td {
    border-bottom: none !important;
  }
}