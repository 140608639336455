.sideBar {
  position: sticky;
  top: 20px;
  max-width: 270px;
  width: 100%;
  height: fit-content;
  color: var(--primary-text);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: sidebar;

  &-userRole {
    font-size: 24px;
    font-weight: 600;
    padding: 5px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ababab;
    margin-bottom: 20px;
  }

  &-menu {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-item {
      cursor: pointer;
      display: flex;
      padding: 10px;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: #e7eef8;
        border-radius: 1rem;
        transition: all 0.2s ease-in-out;
      }

      svg {
        margin-right: 10px;
        width: 25px;
        height: 25px;
      }

      svg {
        font-size: 14px;
      }

      &-active {
        color: #000;
        border-radius: 1rem;
        background-color: #fff;
        font-weight: 500;
        &:hover {
          box-shadow: 0 0 5px 0 #fff;
        }
      }
    }
  }
}
