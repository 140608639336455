.currency {

  &-actions {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .MuiFormControl-root {

      .MuiInputLabel-root,
      input {
        color: white !important;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(221, 221, 221, 0.23) !important;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 15px;
    height: 100%;
  }

  &-table {

    tbody {
      td {
        vertical-align: middle;

        .MuiFormControl-root {

          .MuiInputLabel-root,
          input {
            color: white !important;
          }

          .Mui-disabled {
            -webkit-text-fill-color: #fff;

            .MuiOutlinedInput-notchedOutline {
              border-color: transparent !important;
            }
          }

          .MuiOutlinedInput-notchedOutline {
            border-color: rgba(221, 221, 221, 0.23) !important;
          }
        }
      }
    }
  }
}

.addCurrency {
  display: flex;
  flex-direction: column;

  form {
    .MuiFormControl-root {
      margin-bottom: 10px;
    }
  }
}