.cashier {
  &-menu {
    margin: 32px 0;
    border: 1px solid #767676;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-item {
      cursor: pointer;
      display: flex;
      padding: 10px;
      flex-direction: row;
      align-items: center;
      transition: all 0.2s ease-in-out;
      margin-bottom: 0;

      &:last-child{
        margin-bottom: 0;
      }

      &:hover {
        border-radius: 1rem;
        transition: all 0.2s ease-in-out;
      }

      svg {
        margin-right: 10px;
        width: 25px;
        height: 25px;
        font-size: 14px;
      }

      &-active {
        border-radius: 1rem;
        font-weight: 500;

        &:hover {
          box-shadow: 0 0 5px 0 #fff;
        }
      }
    }
  }
}
