.modalNotification {
    background-color: var(--notification-bg);
    width: 600px;
    min-height: 250px;
    max-height: 650px;
    border-radius: 20px;
    padding: 20px;
    color: var(--dropdown-text);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    position: relative;

    &__count {
        font-size: 14px;
        font-style: italic;
        text-align: right;
        padding-right: 10px;
    }

    &__title {
        font-size: 18px;
        font-weight: 500;
        height: fit-content;
        line-height: 1.3;
        padding-bottom: 10px;
    }

    &__token {
        word-break: break-all;
    }

    &__text {
        font-size: 16px;
        border-top: 2px solid var(--dropdown-text);
        border-bottom: 2px solid var(--dropdown-text);
        padding: 15px 0;
        min-height: 135px;
        overflow-y: auto;
    }

    &__footer {
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .btn {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
        }
    }

    &__date {
        font-size: 16px;
    }
    & .Mui-disabled {
       & svg.MuiSvgIcon-root{
        fill: var(--disable-button);
       } 
    }
    
}