.adminOperations {
  &-filters {
    .department-filters-item {
      margin: 0;
      width: 100%;

      &-input {
        max-width: none;
      }
    }
  }

  &-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    * {
      color: #fff !important;
    }
  }
}

.adminOperations-table {
  & th, td {
    padding-left: 9px;
    padding-right: 9px;
    &:last-child {
      padding: 0px
    }
    &:first-child {
      padding-left: 0px;
    }
  }
  & .storno {
    padding: 0;
  }
}

.admin-operations-sub-table {
  background: var(--secondary-bg);
  & th, td {
    &:last-child {
      padding: 10px
    }
  }
}