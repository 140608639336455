.headerTop {
  width: 100%;
  color: white;
  display: flex!important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-area: header;
  height: 80px;
  margin-left: 0!important;

  & button svg {
    fill: var(--icon);
  }

  .current-time {
    font-size: 20px;
    font-weight: 500;
  }

  &-actions {

    button {
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      margin-right: 4px;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}