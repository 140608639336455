.userMessage {
  min-height: 300px;
  width: 500px;
  padding: 25px 20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    font-size: 20px;
    font-weight: 500;
  }

  &__text {
    font-size: 16px;
    text-align: center;
  }

  &__container {
    text-align: right;
    height: 50px;
  }
}