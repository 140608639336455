.cross-course-two-inputs{
  width: 100%;
  column-gap: 16px;
}

.cash-transit-create-request .MuiTypography-root {
  color: var(--primary-text) !important;
}

.cash-transit-create-request .MuiOutlinedInput-root .Mui-disabled {
  -webkit-text-fill-color: #495057 !important;
}

.cash-transit-create-request .MuiSvgIcon-colorDisabled{
  color: var(--disable-button-secondary-bg) !important;
  &:hover {
    border-color: #495057;
  }
}