.changePassword {
    background-color: #fff;
    border-radius: 35px;
    width: 500px;
    min-height: 250px;
    padding: 20px;

    &__title {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
    }

    &__tooltip {
        margin-top: 10px;
        font-style: italic;
        font-size: 13px;
        line-height: 1.2;
    }

    &__form {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}