.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #1E2027;

  &-container {
    padding: 50px 60px;
    max-width: 550px;
    min-width: 500px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #242731;
    background-clip: border-box;
    border: 0 solid #dee2e6;
    border-radius: 2rem;

    .errorText {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }

    &-title {
      padding: 0;
      line-height: initial;
      font-weight: 500 !important;
      text-align: center;
      font-size: 36px;
      color: #ebebeb;
    }

    &-subTitle {
      padding: 0;
      margin-top: 10px;
      line-height: initial;
      font-weight: 400 !important;
      text-align: center;
      font-size: 20px;
      color: #ebebeb;
    }

    &-button {
      margin-top: 45px;
      margin-bottom: 20px;
    }

    &-form {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & input.MuiInputBase-input:-webkit-autofill  {
        -webkit-box-shadow: 0 0 0 100px #242731d4 inset;
        -webkit-text-fill-color: #fff;
      }

      .MuiFormControl-root {
        border-radius: 5px;

        &:first-child {
          margin-bottom: 10px;
        }

        .MuiFormLabel-root.MuiInputLabel-root {
          color: #6E6E6E;
        }

        .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
          color: #EBEBEB;
        }

        .MuiInputBase-root.MuiOutlinedInput-root:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: #ebebeb;
          }
        }

        .MuiOutlinedInput-input.MuiInputBase-input {
          color: #fff;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #6E6E6E;
          transition: all 300ms;
        }

        .Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid #ebebeb;
          }
        }
      }
    }
  }
}
