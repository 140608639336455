.adminMessages {
    &__actions {
        display: flex;
        gap: 20px;
        height: 550px;
    }

    &__filter {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .MuiFormControl-root select {
            color: #fff;
        }

        .MuiOutlinedInput-root div,
        .MuiOutlinedInput-root svg {
            color: var(--primary-text);
        }
    }

    &__departments {
        width: 40%;
        height: 100%;
    }

    &__addMessage {
        width: 59%;
        height: 100%;
    }

    &__viewMessage {
        display: flex;
        justify-content: center;
    }

    &__deleteModal {
        background-color: var(--main-popup-bg);
        width: 500px;
        height: 200px;
        border-radius: 35px;
        padding: 30px 20px;
        color: #383838;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div:first-child {
            font-size: 20px;
            text-align: center;
        }

        div:last-child {
            text-align: center;
        }
    }
}