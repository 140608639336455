.addMessage {
    border: 1px solid var(--dropdown-text);
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 15px;
    height: 90%;

    &-label {
        padding-top: 5px;
        text-align: right;
    }

    .MuiFormControl-root input,
    .MuiFormControl-root select,
    .MuiFormControl-root textarea {
        color: var(--primary-text);
    }


    .MuiFormControl-root textarea {
        font-size: 16px;
    }

    .MuiOutlinedInput-root div {
        color: var(--primary-text);
    }

    &-textarea .MuiOutlinedInput-root {
        padding-right: 2px;
    }
}