.department {
  &-actions {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 0.5fr repeat(4, 1fr) 1.5fr;
    grid-gap: 20px;
    margin-bottom: 30px;

    &.filters-only {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-filters {

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
      margin-right: 20px;

      &-label {
        width: 70px;
        margin-right: 15px;
      }

      &-input {
        max-width: 200px;
        width: 100%;
      }

      .MuiOutlinedInput-root {
        color: var(--primary-text);
        &:hover {
          border-color: #495057;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #495057;
        &:hover {
          border-color: #495057!important;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #495057!important;
        &:hover {
          border-color: #495057!important;
        }
      }
      .Mui-focused {
        border-color: #495057!important;
      }
    }
  }

}


.selectedDepartment {
  &-title {
    text-align: center;
    font-size: 24px;
  }

  &-table {

    .Mui-disabled {
      color: #3c3c3c;
    }
    thead {
      th {
        font-weight: 700;
        color: rgb(231, 238, 248);
      }
      .MuiTableCell-head {
        border-bottom: 2px solid #90979d;
      }
    }


    tbody {
      td {
        font-weight: 500;
        color: rgb(231, 238, 248);
        border-bottom: 1px solid rgb(73, 80, 87);
      }
    }
  }

  .MuiOutlinedInput-root {
    color: var(--primary-text)!important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #ababab!important;
  }

  .Mui-disabled {
    -webkit-text-fill-color: var(--primary-text)!important;
  }
}
