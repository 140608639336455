/**
 *
 * Basic Formatting
 *
 **/

html {
  box-sizing: border-box;
  font-size: $base-fontsize;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  html {
    font-size: $base-fontsize;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box
}

[hidden] {
  display: none !important;
}

body {
  overflow-y: scroll;
  margin: 0;
  height: 100vh;
  // font-family: 'Montserrat', sans-serif;
  font-family: $base-font;
  font-size: $base-fontsize;
  font-weight: 400;
  line-height: $base-lineHeight;
  text-align: left;
  // background-color: #1E2027;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body.admin {
  background: #F8F8F8;
}

body.no-scrollable {
  overflow: hidden;
}

#root {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headline-font;
  font-weight: 500;
  color: #1E2C4F;
}

h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  line-height: initial;
}

h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  line-height: initial;
}

h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: initial;
}

h4 {
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  line-height: initial;
}

h5 {
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  line-height: initial;
}

h6 {
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  line-height: initial;
}

/**
 * General Text Formattings
 */

b,
strong {
  font-weight: 700;
}

i,
em,
dfn {
  font-style: italic;
}

address {
  font-style: normal;
}

abbr {
  font-variant: small-caps;
  letter-spacing: 1px;
  cursor: help;
}

sub,
sup {
  position: relative;
  font-size: 10px;
  line-height: 10px;
}

sub {
  top: 4px;
}

sup {
  top: -6px;
}

q,
cite,
blockquote {
  font-family: $base-font;
  font-style: italic;
}

hr {
  height: 1px;
  margin: 0;
  border: 0;
  padding: 0;
}

p {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 2em;
}

/**
 * Images
 */

img {
  display: block;
  max-width: 100%;
}

.dashboard {
  display: grid;
  grid-template-areas:
    'sidebar header'
    'sidebar page';
  grid-template-rows: 80px auto;
  grid-template-columns: 270px auto;
  gap: 20px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}

.disabledMenu {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}

.errorText {
  font-size: 15px;
  font-style: italic;
  color: #d34f4f;
}


.row {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  justify-content: flex-start;
}

.disabledButton {
  color: rgba(189, 189, 189, 0.26) !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.MuiButtonBase-root.MuiButton-root {
  height: 100%;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedSuccess {
  border: none;
  background-color: #0C7A2B;
  color: #fff;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedSuccess.Mui-disabled {
  border: 1px solid #0C7A2B;
  background-color: transparent;
  color: #0C7A2B;
  font-weight: 400;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.Mui-disabled {
  border: 1px solid #1976D2;
  background-color: transparent;
  color: #1976D2;
  font-weight: 400;
}
.MuiButton-root.MuiButton-contained.MuiButton-containedError.Mui-disabled {
  border: 1px solid #d32f2f;
  background-color: transparent;
  color: #d32f2f;
  font-weight: 400;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedWarning.Mui-disabled {
  border: 1px solid #ed6c02;
  background-color: transparent;
  color: #ed6c02;
  font-weight: 400;
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
  background-color: var(--dropdown-bg);
  color: var(--dropdown-text);
  border-radius: 5px;

  .MuiList-root.MuiMenu-list {
    padding: 0;

    .MuiButtonBase-root.MuiMenuItem-root.MuiTablePagination-menuItem {
      padding: 5px 10px;
    }

    .Mui-selected {
      color: var(--dropdown-active-text);
      background-color: #1976D2;
    }
  }
}

table.MuiTable-root {

  .Mui-disabled {
    color: #3c3c3c;
  }

  thead {
    th {
      font-weight: 700;
      color: var(--table-text);
    }

    .MuiTableCell-head {
      border-bottom: 2px solid #90979d;
    }
  }


  tbody {
    td {
      font-weight: 400;
      color: var(--table-text);
      border-bottom: 1px solid rgb(73, 80, 87);
    }
  }
}

.MuiTabs-root {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  & .MuiTab-root {
    font-weight: 500;
    color: var(--table-text);
  }
}

input[type="datetime-local"] {
  color-scheme: light dark;
}

.addPopup {
  & .MuiFormLabel-root, .MuiFormControl-root, .MuiTypography-root {
    color: #424242
  }
  & .MuiFormLabel-root.Mui-disabled, .MuiInputBase-root.Mui-disabled {
    color: #00000061;
    & .MuiSelect-select {
      -webkit-text-fill-color: inherit;
    }
    & input {
      -webkit-text-fill-color: #00000061;
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: #0000003b;
    }
  }
  & .MuiInputBase-root, .MuiFormControl-root, .MuiFormControlLabel-root {
    & .MuiButtonBase-root, .MuiSvgIcon-root {
      color: #424242;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #0000003b;
  }
  & .MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.Mui-disabled svg {
    fill: #1976D2;
  }
}
.MuiInputBase-root  input.MuiInputBase-input:-webkit-autofill  {
  -webkit-box-shadow: 0 0 0 100px var(--container-bg) inset;
  -webkit-text-fill-color: var(--table-text);
}