.settings-backward-btn {
    cursor: pointer;
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    color: var(--icon);
    transition: all 0.2s ease-in-out;
    width: fit-content;

    &:hover {
        background-color: var(--menu-button-hover) !important;
        color: var(--dropdown-text);
        border-radius: 10px;
        transition: all 0.2s ease-in-out;
    }
}