.addDep {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

}

.addUsers-grid {
  min-height: 67px;
}

.addUsers-grid .Mui-error {
  margin-top: 0px!important;
}