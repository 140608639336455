.addUsers {

  .MuiFormControl-root {
    margin-bottom: 15px;
  }

  .MuiTextField-root {
    margin-bottom: 0 !important;
  }

  &-two {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .MuiFormControl-root {
      max-width: 310px;
    }
  }

  &-actions {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.close-btn {
  position: absolute;
  right: 32px;
  top: 20px;
  cursor: pointer;
  color: #00000085;
  transition: .4s all;
  &:hover{
    color: #000000d2;
    transition: .4s all;
  }
}

.new-date {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 36px;
}
.set-time{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1.5rem;
  margin-bottom: 41px;

  &-container {
    display: flex;
    gap: 10px;
    height: 58px;
    align-items: center 
  }
}
