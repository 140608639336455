.balanceCashier {
    &-container {
        max-width: 1140px;
        overflow-x: auto;
    }
    &-department{
        padding-bottom: 20px;
    }
    &-department, &-filial {
      & td, th {
        padding-left: 8px;
        padding-right: 8px;
        border-right: 1px solid rgb(73, 80, 87);
      }  
    }
    &-filial tr:nth-child(even) {
        background-color: var(--secondary-bg);
    }
}

.balance-cashier-table {
  & tbody tr td {
    font-weight: 500;
  }
}