.profile {
  flex: 2;
  padding: 20px;
  width: 100%;
  color: white;
  border-radius: 20px;

  &-title {
    font-size: 20px;
    font-weight: 500;
  }
}
