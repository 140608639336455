.settings {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &-menu {
    max-width: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    &-item {
      background-color: var(--main-bg) !important;
      width: 100%;
      display: flex;
      padding: 10px !important;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 1rem!important;
      transition: all 0.2s ease-in-out;
      text-align: center;
      color: var(--primary-text)!important;

      &:hover {
        background-color: var(--menu-button-hover) !important;
        transition: all 0.2s ease-in-out;
        color: var(--menu-button-hover);
      }
      
      svg {
        margin-right: 10px;
        width: 25px;
        height: 25px;
      }
      
      span {
        text-align: center;
        color: inherit;
      }
    }
  }
}