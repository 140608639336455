.userMessages {

    &__table {
        margin-top: 10px;
    }

    &__viewMessage {
        display: flex;
        justify-content: center;
    }
}