.pageWrapper {
  padding: 20px;
  background: #14151A;
  max-width: 1140px;
  min-height: calc(100vh - 120px);
  width: 100%;
  color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}
