.cities {
  &-main {
    display: flex;
    flex-direction: column;

    @media all and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &-actions {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .Mui-disabled {
      border: 1px solid #3c3c3c !important;
      color: #595959 !important;
    }
  }

  &-table {
    width: 100%;
  }
}