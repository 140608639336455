.users {
  &-actions {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 0.5fr 1.3fr 0.9fr 0.9fr 1.4fr;
    grid-gap: 20px;
    margin-bottom: 30px;
    &.filters-only {
      grid-template-columns: 2fr repeat(2, 1fr);
    }
  }

  &-pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiPaginationItem-root,
    .MuiTablePagination-toolbar {
      color: var(--primary-text);

      svg {
        fill: var(--primary-text);
      }
    }

    .MuiTablePagination-displayedRows,
    .MuiTablePagination-actions {
      display: none;
    }
  }

  &-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-label {
        width: 70px;
        margin-right: 15px;
      }

      &-input {
        width: 100%;

        svg {
          fill: #495057;
        }
      }

      .MuiOutlinedInput-root {
        color: var(--primary-text);

        &:hover {
          border-color: #495057;
        }
      }

      .Mui-focused {
        border-color: #495057 !important;
      }
    }
  }
}

.selectedUser {
  max-width: 1140px;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;

  hr {
    background-color: #666666;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 35px;
    width: 100%;
    border-radius: 20px;
  }

  &__name {
    display: flex;
    align-items: center;
  }

  &-title {
    font-size: 20px;
    font-weight: 500;
  }

  &-options {
    padding: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 5px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      font-size: 20px;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &-value {
        color: #707070;
      }
    }
  }
}
