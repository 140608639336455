.departmentsSelector {
    border: 1px solid var(--dropdown-text);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    min-width: 350px;
    width: 100%;
    min-height: 300px;
    height: 90%;
    padding: 10px 10px 10px 5px;
    margin-top: 5px;
    overflow-y: auto;

    &__title {
        font-size: 18px;
    }

    &__items {
        padding: 5px 0 5px 40px;

        .departmentsSelector__title {
            font-size: 16px;
        }
    }
    &__item {
        display: flex;
        align-items: center;
    }
}