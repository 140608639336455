.salary {
  & .MuiButton-root.Mui-disabled span svg.MuiSvgIcon-root {
    fill: var(--button);
  }
}

.reportCashier {
  &-container {
    max-width: 100%;
    overflow-x: auto;
  }
  &-filial {
    padding-top: 40px;
  }
}

.row-title {
  background: #262831;
}

.row-currency {
  background: #262831;
}

.row-total td {
  font-weight: 900!important;
}

.report-data-table tr td:first-child {
  width: 175px;
  border-right: 1px solid #495057;
}
.report-data-table tr td:not(:first-child) {
  min-width: 175px;
}

.header-container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 32px;
}

.row-header td {
  font-weight: 900!important;
}

.row-header, .row-row, .row-total {
  background: var(--secondary-bg);
  & td {
    border-right: 1px solid #90979d;
    &:last-child {
      border-right: none;
    }
  }
}
.row-total td {
  border-bottom: none!important;
  padding-bottom: 20px;
}