.operation-list {
  &-data-picker label {
  color: white !important
  }
  &-button-margin {
    margin-bottom: 10px !important
  }
}

.operation-list-selector .MuiInputLabel-root,
.operation-list-selector .MuiOutlinedInput-root,
.operation-list-selector .MuiSvgIcon-root {
  color: var(--primary-text) !important;
  svg {
    fill: var(--icon);
  }
}

.operation-list-selector .MuiOutlinedInput-notchedOutline {
  border-color: #495057;
  }

.filters-table-wrapper {
  display: flex;
}

.filters-table-wrapper .MuiTable-root {
  margin: 0;
}

.operations-emptyOperations{
  width: 100%;
  margin: 50px 0;
  text-align: center;
}