.report-table {
  & tr td {
    padding-top: 1px!important;
    padding-bottom: 1px!important;
  }
  & tr td:first-child {
    width: 175px;
  }
  & .row-header {
    background: var(--report-header-bg);
    & td {
      border-bottom: 2px solid #a6adb5;
    }
  }
  
  & .row-row {
    background: var(--main-bg);
  }
  
  & .row-total td {
    font-weight: 900!important;
  }
  
  & .row-row, .row-total, .row-header, .row-currency, .row-title {
    & td {
      padding-left: 5px;
      padding-right: 5px;
      width: fit-content;
      border-right: 1px solid rgb(73, 80, 87);
    }
  }
  
  & .row-currency {
    background: var(--secondary-bg);
    & td {
      border-top: 1px solid rgb(73, 80, 87);
    }
  }
  
  & .row-title {
    background-color: var(--report-title-bg);
    & td {
      font-weight: 900!important;
    }
  }
}
