.modalNotification {
    background-color: var(--notification-bg);
    width: 500px;
    min-height: 250px;
    max-height: 300px;
    border-radius: 20px;
    padding: 5px 20px 20px 20px;
    color: var(--dropdown-text);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    position: relative;

    &__count {
        font-size: 14px;
        font-style: italic;
        text-align: right;
        padding-right: 10px;
    }

    &__title {
        font-size: 18px;
        font-weight: 500;
        height: fit-content;
        line-height: 1.3;
        padding-bottom: 5px;
    }

    &__text {
        font-size: 16px;
        border-top: 2px solid var(--dropdown-text);
        border-bottom: 2px solid var(--dropdown-text);
        padding: 5px 0;
        min-height: 135px;
        overflow-y: auto;
    }

    &__footer {
        padding-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__date {
        font-size: 16px;
    }
}