.operations-table {
  & thead tr:first-child th {
    border-right: none;
  }
  & thead th {
    text-align: center;
    & svg {
      cursor: pointer;
      fill: var(--icon);
    }
  }
  & td, th {
    padding-left: 8px;
    padding-right: 8px;
    &:first-child {
      padding-left: 5px;
      padding-right: 5px;
    }
    &:nth-child(odd) {
     border-right: 1px solid #495057;
    }
    &:last-child {
      border-right: none;
    }
  }
  & td {
    font-size: 14px;
  }
}