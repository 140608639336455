.sidebar-cashier {
  padding: 20px 10px;
  border: 1px solid #767676;
  border-radius: 15px;

  &-user {
    display: flex;
    align-items: center;
    margin-bottom: 19px;
  }

  &-name {
    margin-left: 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
  }

  &-address {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    min-height: 32px;
  }

  &-btn {
    max-width: 200px;
    width: 100%;
    background-color: var(--menu-button-hover) !important;
    width: 100%;
    cursor: pointer;
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #f8f8f8;
    border-radius: 1rem;
    transition: all 0.2s ease-in-out;
    text-align: center;
    margin-bottom: 42px !important;
    &-disabled {
      color: #767676 !important;
    }
    &:hover {
      color: var(--menu-button-open-day-hover);
      transition: all 0.2s ease-in-out;
    }
  }

  

  form {
    margin-top: 10px;
    .MuiFormLabel-root, .MuiOutlinedInput-input  {
      color: var(--primary-text) !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #ababab!important;
    }

    .MuiSvgIcon-root {
      fill: var(--icon);
    }

    .Mui-disabled {
      -webkit-text-fill-color: white !important;
      opacity: 0.5;
    }
  }
}
