.balance {
  &-container {
    max-width: 1140px;
    overflow-x: auto;
  }

  &-filials,
  &-departments {
    .Mui-disabled {
      color: #3c3c3c;
    }
    & thead {
      th {
        font-size: 13px;
        line-height: normal;
        letter-spacing: normal;
        padding: 15px 3px;
        border-right: 1px solid rgb(73, 80, 87);
      }
    } 
    tbody {
      tr:nth-child(even) {
        background-color: var(--secondary-bg);
      }
      td {
        width: 'fit-content';
        vertical-align: middle;
        font-size: 12px;
        padding: 15px 3px;
        border-right: 1px solid rgb(73, 80, 87);
        &:first-child {
          cursor: pointer;
          transition: all 0.2s linear;
          &:hover {
            background-color: var(--menu-button-hover);
            transition: all 0.2s linear;
          }
        }
        .MuiFormControl-root {

          .MuiInputLabel-root,
          input {
            color: white !important;
          }

          .Mui-disabled {
            -webkit-text-fill-color: #fff;
          }

          .MuiOutlinedInput-notchedOutline {
            border-color: rgba(221, 221, 221, 0.23) !important;
          }
        }
      }
    }
  }

  &-departments {
    margin-top: 100px;
  }

  &-edit {
    padding: 0 !important;

    &-btn {
      width: 55px;
      background-color: #1976d2;
      border-radius: 10px;
      border: 2px solid #14151A;
      box-sizing: border-box;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.35s ease-in-out;

      &:hover {
        box-shadow: inset 0 0 2px 1px rgb(46, 46, 46);
        background-color: #1363b3;
      }

      svg {
        fill: #fff;
      }
    }

  }
}

.balanceCashier {
  &-container {
      max-width: 1140px;
      overflow-x: auto;
  }
}